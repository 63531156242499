const config = {
    baseUrl: 'https://demo.cadviz.depix.ai/',
    apiUrl: 'https://demo.cadviz.depix.ai/api/v1',
    path: {
        generation: {
            baseUrl: 'style-drive',
            endpoint: {
                generateImages: '/generate-images',
                generatePreciseImages: '/generate-images/precise',
                generateSDXL: '/generate-images/sdxl',
                generateCadviz: '/cadviz-generate-image-autoref',
                generateCadvizFull: '/cadviz-generate-autoref-full'
            }
        },
        auth: {
            baseUrl: 'auth',
            endpoint: {
                login: '/login',
                refresh: '/refresh'
            }
        },
        users: {
            baseUrl: 'users',
            endpoint: {
                update: '/update',
                register: '/register',
                getAll: '/',
                changePassword: '/change-password'
            }
        }
    }
};

export default config;
